export default {
  proxy_path: "https://l1l77m407i.execute-api.us-east-1.amazonaws.com/api",
  proxy_path_geojson_api: "https://vm3tx6rci7.execute-api.us-east-1.amazonaws.com/api",
  google_api_key: "AIzaSyCIWGqqG3v6U3gNYVZ7NjrcCPBVpx1pfgc",
  main_company_term_id: "6",
  crm_base_url: "rossing.forwoodsafety.com",
  custom_tile_url: "https://mapoverlays.geoeditor.rossing.forwoodsafety.com",
  MAP_REPORT_ACCESS_ROLE: "MapReportAccess",
  forwoodId: {
    URL: "https://id.rossing.forwoodsafety.com",
    APP_CLIENT_ID: "5klgvff4f29cmc3nasv8js4hgr",
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://9l932f5q8h.execute-api.us-east-1.amazonaws.com/prod",
  },
  reactApp: {
    HOSTNAME: "https://mapreport.rossing.forwoodsafety.com",
  },
  get forwoodIdAuthUrl() {
    return `${
      this.forwoodId.URL
    }/authorize?response_type=token&scope=openid&client_id=${
      this.forwoodId.APP_CLIENT_ID
    }&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  },
};
